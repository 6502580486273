<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dark: false
    }
  },
  created() {
    window.getApp = this
  },
  name: 'App'
}
</script>