<template>
  <v-app>
    <v-app-bar
        color="indigo"
        dark
        app
    >

      <v-spacer></v-spacer>
      <v-menu
          offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">
            {{ uname }}
          </v-btn>
        </template>

        <v-list>
<!--          <v-list-item>-->
<!--            <v-list-item-title>个人设置</v-list-item-title>-->
<!--          </v-list-item>-->
          <v-list-item @click="logout">
            <v-list-item-title>登出</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-content>
      <router-view/>
    </v-content>
    <v-footer
        color="indigo"
        app
    >
      <span class="white--text">&copy; 2022</span>
    </v-footer>
  </v-app>
</template>

<script>
import {removeSession} from '@/components/object-helper'

export default {
  name: 'Index',

  data: () => ({
    timeOutMillsecond: 60 * 60 * 1000,
    uname: '',
  }),
  mounted() {
    this.uname = sessionStorage.getItem('uname')
    this.timeOut()
  },
  methods: {
    logout() {
      removeSession()
      this.$router.replace('/login')
    },
    timeOut() {
      let last = sessionStorage.getItem("lastRequest");
      if (!last) {
        this.logout();
        return false
      }
      let diff = new Date().getTime() - Number(last);
      if (diff > this.timeOutMillsecond) {
        this.logout();
        return false
      }
    },

  }
};
</script>
