<template>
    <v-card>
        <v-card-title>
            <v-btn class="mb-2 ml-2" color="primary" plain @click="toCert">证书管理</v-btn>
            <v-btn class="mb-2 ml-2" color="primary" plain @click="toUnbind">未激活设备</v-btn>
            <v-btn class="mb-2 ml-2" color="primary" plain @click="toUser">用户管理</v-btn>
        </v-card-title>
        <v-card-subtitle>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                hide-details
                label="用户名"
                single-line
            ></v-text-field>
        </v-card-subtitle>
        <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            class="elevation-1 mt-2 ml-2 mr-2"
        >
            <!-- 数据表格 头部插槽配置 -->
            <template v-slot:top>
                <v-toolbar color="white" flat>
                    <v-spacer></v-spacer>
                    <v-btn class="mb-2" color="primary" dark @click="add">添加用户</v-btn>
                    <v-dialog v-model="dialog" max-width="500px" persistent>
                        <v-card :loading="loading">
                            <v-card-title>
                                <span class="headline">添加</span>
                            </v-card-title>

                            <v-card-text>
                                <v-form
                                    ref="form"
                                >
                                    <v-text-field
                                        v-model="item.userid"
                                        label="用户名*"
                                        :readonly="editedIndex != -1"
                                        required
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="item.passwd"
                                        label="密码*"
                                        required
                                        type="password"
                                    ></v-text-field>
                                </v-form>
                                <small>*为必填项</small>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="dialog = false">取消</v-btn>
                                <v-btn color="primary darken-1" @click="save">保存</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.admin="{ item }">
                <v-chip
                    :color="item.admin === 1 ? 'red' : ''"
                    :text-color="item.admin === 1 ? 'white' : ''"
                    label
                >
                    {{ item.admin === 1 ? '是' : '否' }}
                </v-chip>
            </template>
            <template v-slot:item.openid="{ item }">
                {{ !item.openid ? '未绑定' : '已绑定' }}
            </template>
            <!-- 数据表格 操作部分配置 -->
            <template v-slot:item.actions="{ item }">
                <v-btn class="ml-2" color="red" outlined small @click="add(item)"
                >修改密码
                </v-btn>
            </template>
            <!-- 数据表格 无数据状态展示 -->
            <template v-slot:no-data>
                <v-btn outlined small text>
                    暂无数据, 点击刷新
                </v-btn>
            </template>
        </v-data-table>
    </v-card>
</template>
<script>
import {removeSession} from '@/components/object-helper'

export default {
    name: "User",
    data() {
        return {
            dialog: false,
            loading: false,
            search: '',
            paths: [
                {
                    text: '用户管理',
                    disabled: true,
                    href: '/user',
                },
            ],
            headers: [
                {text: '用户名', sortable: false, value: 'userid'},
                {text: '超管', sortable: false, value: 'admin'},
                {text: '绑定', sortable: false, value: 'openid'},
                {text: '操作', sortable: false, value: 'actions'},
            ],
            editedIndex: -1,
            items: [],
            item: {
                id: null,
                userid: null,
                passwd: null,
                openid: null,
                admin: 0
            },
        }
    },
    computed: {},
    mounted() {
        let vm = this
        this.loading = true
        this.$axios.get('/admin', {}).then(response => {
            if (response.status === 200 && response.data && response.data.code === 'SUCCESS') {
                let data = response.data.data
                this.items = data
            } else {
                if (response.data.code === 'WRONGBASEAUTH') {
                    removeSession()
                    this.$router.replace('/login')
                }
                vm.$notify.error(response.data.errorMsg)
            }
        }).finally(() => {
            this.loading = false
        })
    },
    methods: {
        add(item) {
            this.reset()
            console.log(item)
            if (item) {
                this.editedIndex = this.items.indexOf(item)
                this.item = Object.assign({}, {
                    id: item.id,
                    userid: item.userid,
                    openid: item.openid,
                    admin: item.admin
                })

            }
            this.dialog = true
        },
        checkform() {
            if (!this.item.userid) {
                this.$notify.error('请输入用户名')
                return false
            }
            if (!this.item.passwd) {
                this.$notify.error('请输入密码')
                return false
            }
            return true
        },
        save() {
            let vm = this
            this.loading = true
            if (this.checkform()) {
                if(this.editedIndex != -1) {
                    this.$axios.put('/admin/' + this.item.id, this.item).then(response => {
                        if (response.status === 200 && response.data && response.data.code === 'SUCCESS') {
                            vm.$notify.error('修改成功')
                        } else {
                            if (response.data.code === 'WRONGBASEAUTH') {
                                removeSession()
                                vm.$router.replace('/login')
                            }
                            vm.$notify.error(response.data.errorMsg)
                        }
                    }).finally(() => {
                        this.loading = false
                        this.dialog = false
                        this.reset()
                    })
                }else{
                    this.$axios.post('/admin', this.item).then(response => {
                        if (response.status === 200 && response.data && response.data.code === 'SUCCESS') {
                            if (vm.editedIndex === -1) {
                                vm.items.push(response.data.data)
                            }
                        } else {
                            if (response.data.code === 'WRONGBASEAUTH') {
                                removeSession()
                                vm.$router.replace('/login')
                            }
                            vm.$notify.error(response.data.errorMsg)
                        }
                    }).finally(() => {
                        this.loading = false
                        this.dialog = false
                        this.reset()
                    })
                }
            }
            this.loading = false
        },
        toUnbind() {
            this.$router.push('/unbind')
        },
        toUser() {
            this.$router.push('/user')
        },
        toCert() {
            this.$router.push('/cert')
        },
        reset() {
            this.loading = false
            this.editedIndex = -1
            this.item = {
                id: null,
                userid: null,
                openid: null,
                passwd: null,
                admin: 0,
            }
        },
    }
}
</script>