<template>
    <v-app id="inspire">
        <v-content>
            <v-container
                class="fill-height"
                fluid
            >
                <v-row
                    align="center"
                    justify="center"
                >
                    <v-col
                        cols="12"
                        md="4"
                        sm="8"
                    >
                        <v-card v-if="state === 'login'">
                            <v-toolbar
                                color="primary"
                                dark
                                flat
                            >
                                <v-toolbar-title>{{ $store.state.appTitle }}</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-row
                                    align-content="center"
                                    class="fill-height"
                                    justify="center"
                                >
                                    <v-col
                                        class="text-subtitle-1 text-center"
                                        cols="12"
                                    >
                                        正在登录中
                                    </v-col>
                                    <v-col cols="6">
                                        <v-progress-linear
                                            height="20"
                                            indeterminate
                                            rounded
                                        ></v-progress-linear>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card v-else class="elevation-12">
                            <v-toolbar
                                color="primary"
                                dark
                                flat
                            >
                                <v-toolbar-title>{{ $store.state.appTitle }}</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-form>
                                    <v-text-field
                                        v-model="user"
                                        append-icon="fa fa-user"
                                        label="用户名"
                                        name=""
                                        outlined
                                        type="text"
                                    />

                                    <v-text-field
                                        id="password"
                                        v-model="passwd"
                                        append-icon="fa fa-lock"
                                        label="密码"
                                        name="passwd"
                                        outlined
                                        type="password"
                                    />
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn color="primary" @click="reg">登录</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-content>
    </v-app>
</template>

<script>
export default {
    name: "Login",
    data: () => ({
        state: 'login',
        user: null,
        passwd: null,
        openid: null,
    }),
    mounted() {
        this.login()
    },
    methods: {
        login() {
            let vm = this
            let code = this.$route.query.code
            this.$axios.get('/login?code=' + code).then(response => {
                if (response && response.status === 200 && response.data && response.data.code === 'SUCCESS') {
                    console.info("login-resp--->", response.data)
                    let data = response.data.data;
                    sessionStorage.setItem('token', data.token)
                    sessionStorage.setItem('uname', data.userid)
                    sessionStorage.setItem('user', JSON.stringify(data))
                    sessionStorage.setItem("lastRequest", new Date().getTime());

                    this.$router.replace('/cert')
                } else {
                    vm.state = 'reg'
                    vm.openid = response.data.data.openid
                    vm.$notify('该微信未绑定, 请输入账号密码进行绑定')
                }
            })
        },
        reg() {
            if(!this.user){
                this.$notify('请输入用户名')
                return false
            }
            if (!this.passwd) {
                this.$notify.error('请输入密码')
                return false
            }
            let vm = this
            this.$axios.post('/reg', {userid:this.user, passwd:this.passwd, openid: this.openid}).then(response => {
                if (response && response.status === 200 && response.data && response.data.code === 'SUCCESS') {
                    console.info("reg-resp--->", response.data)
                    let data = response.data.data;
                    sessionStorage.setItem('token', data.token)
                    sessionStorage.setItem('uname', data.userid)
                    sessionStorage.setItem('user', JSON.stringify(data))
                    sessionStorage.setItem("lastRequest", new Date().getTime());

                    this.$router.replace('/cert')
                    vm.state = 'login'
                    vm.$notify.error('绑定成功')
                }
            })
        },
    }
}
</script>