import axios from 'axios'
// import Vue from 'vue'
import HttpStatus from 'http-status'

let instance = axios.create({
    baseURL: 'http://42.194.178.243:8008/cfkey'
    // baseURL: 'http://192.168.1.243:8096/cfkey'
})

instance.interceptors.request.use(config => {
    let token = sessionStorage.getItem('token')
    if (token) {
        config.headers.Token = token
    }
    return config
})

instance.interceptors.response.use((response) => {
    return response
}, (error) => {
    if (error.message.indexOf('Network Error') >= 0) {
        // Vue.prototype.$notify.error('Network error, please check your network settings!')
        console.error('http-common----->Network error, please check your network settings!')
    } else if (error.response.status === HttpStatus.UNAUTHORIZED) {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('username')
        // Vue.prototype.$notify.error('Authorized failed,please login.')
        console.error('http-common----->Authorized failed,please login.')
    } else if (error.response.status >= HttpStatus.BAD_REQUEST) {
        // Vue.prototype.$notify.error(error.response.data.message)
        console.error('http-common----->' + error.response.data.message)
    }else{
        // Vue.prototype.$notify.error("Has error.")
        console.error('http-common----->Has error.')
    }
})

export const AXIOS = instance
