<template>
    <v-card>
        <v-card-title>
            <v-btn class="mb-2 ml-2" plain color="primary" @click="toCert">证书管理</v-btn>
            <v-btn class="mb-2 ml-2" plain color="primary" @click="toUnbind">未激活设备</v-btn>
            <v-btn class="mb-2 ml-2" plain color="primary" @click="toUser">用户管理</v-btn>
        </v-card-title>
        <v-card-subtitle>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                hide-details
                label="硬件唯一码"
                single-line
            ></v-text-field>
        </v-card-subtitle>
        <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            class="elevation-1 mt-2 ml-2 mr-2"
        >
            <!-- 数据表格 头部插槽配置 -->
            <template v-slot:top>
                <v-toolbar color="white" flat>
                    <v-toolbar-title>未激活设备</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px" persistent>
                        <v-card :loading="loading">
                            <v-card-title>
                                <span class="headline">激活</span>
                            </v-card-title>

                            <v-card-text>
                                <v-form
                                    ref="form"
                                >
                                    <v-select
                                        v-model="item.certId"
                                        :items="certs"
                                        item-text="id"
                                        item-value="id"
                                        label="选择证书"
                                        @change="changeCert"
                                    ></v-select>
                                    <v-select
                                        v-model="selectKey"
                                        :hint="`最大数量: ${selectKey.maxnum},  已激活: ${selectKey.currnum}`"
                                        :items="keys"
                                        item-text="key"
                                        item-value="id"
                                        label="选择激活码"
                                        persistent-hint
                                        return-object
                                    ></v-select>
                                </v-form>
                                <small>*为必填项</small>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="dialog = false">取消</v-btn>
                                <v-btn color="primary darken-1" @click="save">保存</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <!-- 数据表格 操作部分配置 -->
            <template v-slot:item.actions="{ item }">
                <v-btn class="ml-2" color="red" outlined small @click="add(item)"
                >激活
                </v-btn>
            </template>
            <!-- 数据表格 无数据状态展示 -->
            <template v-slot:no-data>
                <v-btn outlined small text>
                    暂无数据, 点击刷新
                </v-btn>
            </template>
        </v-data-table>
    </v-card>
</template>
<script>
import {removeSession} from '@/components/object-helper'

export default {
    name: "UnbindDevice",
    data() {
        return {
            dialog: false,
            loading: false,
            search: '',
            paths: [
                {
                    text: '未激活设备',
                    disabled: true,
                    href: '/unbind',
                },
            ],
            headers: [
                {text: '唯一码', sortable: false, value: 'devfp'},
                {text: 'ip', sortable: false, value: 'ip'},
                {text: '操作', sortable: false, value: 'actions'},
            ],
            editedIndex: -1,
            items: [],
            certs: [],
            keys: [],
            selectKey: {
                maxnum: 0,
                currnum: 0
            },
            item: {
                id: null,
                devfp: null,
                ip: null,
                keyId: null,
                certId: null
            },
        }
    },
    computed: {},
    mounted() {
        let vm = this
        this.loading = true
        this.$axios.get('/unbinds', {}).then(response => {
            if (response.status === 200 && response.data && response.data.code === 'SUCCESS') {
                let data = response.data.data
                this.items = data
            } else {
                if (response.data.code === 'WRONGBASEAUTH') {
                    removeSession()
                    this.$router.replace('/login')
                }
                vm.$notify.error(response.data.errorMsg)
            }
        }).finally(() => {
            this.loading = false
        })
    },
    methods: {
        add(item) {
            this.reset()
            this.loading = true
            let vm = this
            this.editedIndex = this.items.indexOf(item)
            this.item = Object.assign({}, item)
            this.$axios.get('/certs', {}).then(response => {
                if (response.status === 200 && response.data && response.data.code === 'SUCCESS') {
                    let data = response.data.data
                    this.certs = data
                } else {
                    if (response.data.code === 'WRONGBASEAUTH') {
                        removeSession()
                        this.$router.replace('/login')
                    }
                    vm.$notify.error(response.data.errorMsg)
                }
            }).finally(() => {
                this.loading = false
            })
            this.dialog = true
        },
        changeCert(value) {
            if (value) {
                let vm = this
                this.$axios.get('/keys?id=' + value, {}).then(response => {
                    if (response.status === 200 && response.data && response.data.code === 'SUCCESS') {
                        let data = response.data.data
                        this.keys = data
                    } else {
                        if (response.data.code === 'WRONGBASEAUTH') {
                            removeSession()
                            this.$router.replace('/login')
                        }
                        vm.$notify.error(response.data.errorMsg)
                    }
                }).finally(() => {
                    this.loading = false
                })
            }
        },
        checkform() {
            if (!this.selectKey) {
                this.$notify.error('请选择激活码')
                return false
            } else {
                if (this.selectKey.currnum >= this.selectKey.maxnum) {
                    this.$notify.error('当前激活码已无可激活数量')
                    return false
                } else {
                    this.item.keyId = this.selectKey.id
                }
            }

            if (!this.item.keyId) {
                this.$notify.error('请选择激活码')
                return false
            }
            if (!this.item.certId) {
                this.$notify.error('请选择激活码数量')
                return false
            }
            return true
        },
        save() {
            let vm = this
            this.loading = true
            if (this.checkform()) {
                console.log(this.item)
                this.$axios.put('/devices/' + this.item.id, this.item).then(response => {
                    if (response.status === 200 && response.data && response.data.code === 'SUCCESS') {
                        vm.items.splice(vm.editedIndex, 1)
                    } else {
                        if (response.data.code === 'WRONGBASEAUTH') {
                            removeSession()
                            vm.$router.replace('/login')
                        }
                        vm.$notify.error(response.data.errorMsg)
                    }
                }).finally(() => {
                    this.loading = false
                    this.dialog = false
                    this.reset()
                })
            }
            this.loading = false
        },
        toUnbind(){
            this.$router.push('/unbind')
        },
        toUser(){
            this.$router.push('/user')
        },
        toCert(){
            this.$router.push('/cert')
        },
        reset() {
            this.loading = false
            this.item = {
                id: null,
                devfp: null,
                ip: null,
                keyId: null,
                certId: null
            }
        },
    }
}
</script>