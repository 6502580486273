<template>
    <v-card>
        <v-card-title>
            <v-btn class="mb-2 ml-2" plain color="primary" @click="toCert">证书管理</v-btn>
            <v-btn class="mb-2 ml-2" plain color="primary" @click="toUnbind">未激活设备</v-btn>
            <v-btn class="mb-2 ml-2" plain color="primary" @click="toUser">用户管理</v-btn>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-1 mt-2 ml-2 mr-2"
        >
            <!-- 数据表格 头部插槽配置 -->
            <template v-slot:top>
                <v-breadcrumbs :items="paths"></v-breadcrumbs>
                <v-toolbar color="white" flat>
                    <v-toolbar-title>激活码管理</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn class="mb-2" color="primary" dark @click="add">添加激活码</v-btn>
                    <v-dialog v-model="dialog" max-width="500px" persistent>
                        <v-card :loading="loading">
                            <v-card-title>
                                <span class="headline">添加激活码</span>
                            </v-card-title>

                            <v-card-text>
                                <v-form
                                    ref="form"
                                >
                                    <v-text-field
                                        v-model="item.key"
                                        label="激活码*"
                                        required
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="item.maxnum"
                                        label="激活数量*"
                                        required
                                    ></v-text-field>
                                </v-form>
                                <small>*为必填项</small>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="dialog = false">取消</v-btn>
                                <v-btn color="primary darken-1" @click="save">保存</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <!-- 数据表格 操作部分配置 -->
            <template v-slot:item.actions="{ item }">
                <v-btn class="ml-2" color="blue" outlined small @click="
                () => {
                  $router.push('/device?id=' + item.id);
                }
              "
                >已激活设备
                </v-btn>
                <v-btn class="ml-2" color="red" outlined small
                >编辑
                </v-btn>
            </template>
            <!-- 数据表格 无数据状态展示 -->
            <template v-slot:no-data>
                <v-btn outlined small text>
                    暂无数据, 点击刷新
                </v-btn>
            </template>
        </v-data-table>
    </v-card>
</template>
<script>
import {removeSession} from '@/components/object-helper'

export default {
    name: "Facekey",
    data() {
        return {
            dialog: false,
            loading: false,
            paths: [
                {
                    text: '证书管理',
                    disabled: false,
                    href: '/cert',
                },{
                    text: '激活码管理',
                    disabled: true,
                    href: '/key',
                },
            ],
            headers: [
                {text: 'ID', sortable: false, value: 'id'},
                {text: 'key', sortable: false, value: 'key'},
                {text: '最大数量', sortable: false, value: 'maxnum'},
                {text: '已激活', sortable: false, value: 'currnum'},
                {text: '操作', sortable: false, value: 'actions'},
            ],
            items: [],
            item: {
                id: null,
                key: null,
                maxnum: 1
            },
        }
    },
    computed: {},
    mounted() {
        let vm = this
        this.loading = true
        let id = this.$route.query.id
        this.$axios.get('/keys?id=' + id, {}).then(response => {
            if (response.status === 200 && response.data && response.data.code === 'SUCCESS') {
                let data = response.data.data
                this.items = data
            } else {
                if (response.data.code === 'WRONGBASEAUTH') {
                    removeSession()
                    this.$router.replace('/login')
                }
                vm.$notify.error(response.data.errorMsg)
            }
        }).finally(() => {
            this.loading = false
        })
    },
    methods: {
        add() {
            this.reset()
            this.dialog = true
        },
        checkform() {
            if (!this.item.key) {
                this.$notify.error('请输入激活码')
                return false
            }
            if(!this.item.maxnum || this.item.maxnum <= 0){
                this.$notify.error('请输入激活码数量')
                return false
            }
            return true
        },
        save() {
            let vm = this
            this.loading = true
            if (this.checkform()) {
                this.item.certId = this.$route.query.id
                this.$axios.post('/keys', this.item).then(response => {
                    if (response.status === 200 && response.data && response.data.code === 'SUCCESS') {
                        vm.items.push(response.data.data)
                    } else {
                        if (response.data.code === 'WRONGBASEAUTH') {
                            removeSession()
                            vm.$router.replace('/login')
                        }
                        vm.$notify.error(response.data.errorMsg)
                    }
                }).finally(() => {
                    this.loading = false
                    this.dialog = false
                    this.reset()
                })
            }
            this.loading = false
        },
        reset() {
            this.loading = false
            this.item = {
                id: null,
                key: null,
                maxnum: 1
            }
        },
        toUnbind(){
            this.$router.push('/unbind')
        },
        toUser(){
            this.$router.push('/user')
        },
        toCert(){
            this.$router.push('/cert')
        },
    }
}
</script>