import objectAssign from 'object-assign'

const mergeOptions = function ($vm, options) {
    const defaults = {}
    for (let i in $vm.$options.props) {
        if (i !== 'value') {
            defaults[i] = $vm.$options.props[i].default
        }
    }
    const _options = objectAssign({}, defaults, options)
    for (let i in _options) {
        $vm[i] = _options[i];
    }
}

const removeSession = function () {
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('user')
    sessionStorage.removeItem('uname')
    sessionStorage.removeItem('lastRequest')
}

/***

 * @return {*} MonthFirstDay 返回本月第一天的时间

 */

const showMonthFirstDay = function () {
    let Nowdate = new Date();
    let MonthFirstDay = new Date(Nowdate.getFullYear(), Nowdate.getMonth());
    return [MonthFirstDay.getFullYear(), MonthFirstDay.getMonth() + 1, MonthFirstDay.getDate()].join('-');
}


/***

 * @return {*} MonthLastDay 返回本月最后一天的时间

 */

const showMonthLastDay = function () {
    let Nowdate = new Date();
    let MonthNextFirstDay = new Date(Nowdate.getFullYear(), Nowdate.getMonth() + 1);
    let MonthLastDay = new Date(MonthNextFirstDay - 86400000);
    return [MonthLastDay.getFullYear(), MonthLastDay.getMonth() + 1, MonthLastDay.getDate()].join('-');
}

//yyyy-MM-dd HH24:mi:ss
const getDatetime = function (data) {
    let date = new Date(data);
    let Dates = [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-')
    let times = [date.getHours(), date.getMinutes(), date.getSeconds()].join(':')
    return Dates + ' ' + times
}

//时间  00:00:00
const getTime = function (data) {
    let date = new Date(data);
    let times = [date.getHours(), date.getMinutes(), date.getSeconds()].join(':')
    return times
}

//日期 2018-01-01
const getDate = function (data) {
    let date = new Date(data);
    let Dates = [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-')
    return Dates
}
//月份
const getMonth = function (data) {
    let date = new Date(data);
    let Dates = [date.getFullYear(), date.getMonth() + 1].join('-')
    return Dates
}

//比较大小
const compareDate = function(date1, date2){
    let d1 = new Date(date1);
    let d2 = new Date(date2);
    return d1 > d2
}


export {
    mergeOptions,
    removeSession,
    showMonthLastDay,
    showMonthFirstDay,
    getDatetime,
    getTime,
    getDate,
    getMonth,
    compareDate,
}