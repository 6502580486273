import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'
import Index from '../views/Index'
import Facecert from "@/views/Facecert";
import Facekey from "@/views/Facekey";
import Device from "@/views/Device";
import UnbindDevice from "@/views/UnbindDevice";
import User from "@/views/User";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index,
        redirect: '/cert',
        meta: {
            requireLogin: true,
        },
        children: [
            {
                path: '/cert',
                name: 'Facecert',
                component: Facecert,
                meta: {
                    requireLogin: true
                }
            },{
                path: '/key',
                name: 'Facekey',
                component: Facekey,
                meta: {
                    requireLogin: true
                }
            },{
                path: '/device',
                name: 'Device',
                component: Device,
                meta: {
                    requireLogin: true
                }
            },{
                path: '/unbind',
                name: 'UnbindDevice',
                component: UnbindDevice,
                meta: {
                    requireLogin: true
                }
            },{
                path: '/user',
                name: 'User',
                component: User,
                meta: {
                    requireLogin: true
                }
            }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requireLogin: false,
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
