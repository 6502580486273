import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {AXIOS} from './components/http-common'
import vuetify from './plugins/vuetify'
import Notify from './components/notify'

Vue.config.productionTip = false

Vue.use(Notify)

Vue.prototype.$axios = AXIOS

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireLogin)) {
        if (sessionStorage.getItem('token')) {
            next()
        } else {
            next({
                path: '/login',
                query: {redirect: to.fullPath}
            })
        }
    } else {
        next()
    }
})